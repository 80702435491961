<template>
  <div>
    <Contact/>
    <section class="slice slice-lg" style="padding-top: 0;">
      <div class="container">
        <div class="py-6">
          <div class="row">
            <div class="col-lg-4">
              <div class="card text-center hover-translate-y-n10 hover-shadow-lg">
                <img src="@/img/about/about9.png"/>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card text-center hover-translate-y-n10 hover-shadow-lg" style="background:#18b29d;">
							<span class="card-span">
                东之林科技（深圳）有限公司隶属于深圳市斯贝达电子有限公司，斯贝达成立于2009年。经过十五年发展，
                打下了坚实的产品基础，积累了丰富的行业经验，主要面向科研院所、电力、变频、新能源、服务器、
                轨道交通等领域的企业提供产品和技术服务。
							</span>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card text-center hover-translate-y-n10 hover-shadow-lg">
                <img src="@/img/about/about10.png"/>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card text-center hover-translate-y-n10 hover-shadow-lg" style="background:#92f0e4;">
							<span class="card-span">
									深圳市斯贝达电子在四川创立了分公司和工业软件研发中心。<b>工业软件研发
                中心技术人员占比80%</b>，以来自<b>PTC和
                西门子等原厂实施顾问</b>的实施经验，
                联合<b>中北大学</b>的科研技术能力打造公司
                核心竞争力，专业从事<b>工业软件产品研发、
                技术服务、工业信息化技术咨询。</b>
							</span>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card text-center hover-translate-y-n10 hover-shadow-lg">
                <img src="@/img/about/about11.png"/>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card text-center hover-translate-y-n10 hover-shadow-lg" style="background:#92f0e4;">
							<span class="card-span">
								工业软件研发中心主要业务:
                航空航天、国防电子、高科技电子、装备、汽车、家用电器、
                通用机械等研发型和制造型企业提供专业的、
                整体的信息化应用解决方案，以协助客户<b>提高产品创新能力、
                缩短产品研制周期、降低产品制造成本、加快供应链响应速度、
                改善售后服务质量以及提升企业管理效率。</b>
							</span>
              </div>
            </div>
          </div>
        </div>
        <div class="py-6">
          <div class="row row-grid justify-content-between align-items-center">
            <div class="col-lg-5">
              <h5 class="h3">企业文化</h5>
              <p class="lead my-4">
                团队核心技术成员来自于工业软件巨头PTC公司及国内知名工业软件研发公司，同时吸纳并培养了一批优秀的PLM软件实施顾问。
              </p>
              <p class="lead my-4">
                我们是一支专业的团队。我们的成员大多拥有超过十年的行业实施经验，具有丰富的多行业业务经验、及专业技术背景，同时得助合作高校学术研发的支持，弥补了学术上的短板。
              </p>
              <p class="lead my-4">
                我们是一支成熟的团队。我们的平均年龄大约34岁，既不失朝气与奋进，又具备务实与创新。
              </p>
              <p class="lead my-4">
                我们是一支专注的团队。我们团队多年来始终如一的专注于工业信息化的建设服务，贯穿整个产品生命周期，为我们自己的产品研发打下了坚实的技术基础。
              </p>
              <p class="lead my-4">
                我们是一支有梦想的团队。我们来自五湖四海，因为一个共同的梦想：将多年积累的业务、技术经验赋能于客户的信息化建设中去，打造全新的信息化服务模式，切实满足客户的需求。
              </p>
            </div>
            <div class="col-lg-6">
              <div class="card mb-0 ml-lg-5">
                <div class="card-body p-2">
                  <img alt="Image placeholder" class="img-fluid shadow rounded" src="@/img/about/about3.png">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="py-6">
          <div class="row row-grid justify-content-between align-items-center">
            <div class="col-lg-5 order-lg-2">
              <h5 class="h3">核心业务</h5>
              <p class="lead my-4" style="margin: 0 !important;">
                工业软件产品
              </p>
              <ul>
                <li>SPLM产品全生命周期管理平台</li>
                <li>SPMS科研项目管理系统</li>
                <li>SMPM结构化工艺管理系统</li>
                <li>SpeedDLink设计工具集成软件</li>
                <li>SpeedVue轻量可视化软件</li>
              </ul>
              <p class="lead my-4" style="margin: 0 !important;">
                项目实施
              </p>
              <ul>
                <li>自主产品的实施服务与技术支持</li>
                <li>西门子公式Teamcenter系统实施服务</li>
                <li>PTC公司Windchill、Integrity 系统实施服务</li>
              </ul>
              <p class="lead my-4" style="margin: 0 !important;">
                信息化咨询
              </p>
              <ul>
                <li>数字化工厂</li>
                <li>智慧院所</li>
                <li>应用系统集成</li>
                <li>业务流程优化</li>
              </ul>
              <p class="lead my-4" style="margin: 0 !important;">
                培训与运维
              </p>
              <ul>
                <li>信息化培训</li>
                <li>系统运维服务</li>
              </ul>
            </div>
            <div class="col-lg-6 order-lg-1">
              <div class="card mb-0 mr-lg-5">
                <div class="card-body p-2">
                  <img alt="Image placeholder" class="img-fluid shadow rounded" src="@/img/about/about4.png">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer/>
  </div>
</template>
<script>


export default {
  name: 'about',
}
</script>
<style scoped>
.aboutimg{
  width: 216px;
  height: 80px;
}
.flex_jc{
  display: flex;
  justify-content: center;
  text-align: center;
}
/* .imghover:hover{
  transition:1s;
  transform:scale(1.1);
}
.imghover{
  height: 350px !important;
} */
.card-span{
  text-indent:2rem;
  text-align: justify;
  margin: 1rem;
  color: #171347;
  font-size: 18px;

}
/* 悬浮样式 */
.white_content {
  position: fixed;
  right: 10px;
  bottom: 200px;
  z-index: 999;
  background: none;
}
.white_content-1 {
  background: #50b5ff;
  padding: 10px 20px;
  border-radius: 30%;
  text-align: center;
  color: #171347;
}
</style>
